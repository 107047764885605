import '../styles/demo.css';

import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const DemoPage = () => (
  <Layout>
    <SEO
      title="Page Three"
      description="Page three description"
    />

    <h1 id="cat">Hi This is Demo page</h1>

    <p>Welcome to Demo Page 3</p>

    <Link to="/">Go back to the homepage</Link>
  </Layout>
);

export default DemoPage;
